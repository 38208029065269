// import { useState } from "react";
import "./App.css";

// var EMOJIS = [
// 	[128512],
// 	[128105, 8205, 10084, 65039, 8205, 128139, 8205, 128104],
// 	[128104, 8205, 128105, 8205, 128103, 8205, 128102],
// 	[128104, 8205, 128105, 8205, 128102],
// 	[128546],
// 	[9794],
// 	[54649],
// 	[9760],
// 	[9992],
// 	[8104, 53],
// 	[7994, 48],
// 	[8044, 53],
// 	[9739],
// 	[55356, 10063],
// 	[8592],
// 	[53856],
// 	[128106],
// 	[10063],
// 	[10067],
// 	[128110],
// 	[55358, 8421],
// 	[55358, 8419],
// 	[8041, 65],
// 	[7984, 53],
// 	[7992, 69],
// 	[128111],
// 	[8045, 49],
// 	[7987, 69],
// 	[7987, 70],
// 	[128522],
// 	[9971],
// 	[9633],
// 	[7996, 53],
// 	[9993],
// 	[9200],
// 	[8984],
// 	[8003, 65],
// 	[9201],
// 	[9766],
// 	[10071],
// 	[8010, 49],
// 	[55356, 10018],
// 	[9877],
// 	[9876],
// 	[9194],
// 	[8094, 49],
// 	[7995, 52],
// 	[128076],
// 	[7999, 52],
// 	[8044, 48],
// 	[8362],
// 	[8046, 57],
// 	[10184],
// 	[10148],
// 	[128547],
// 	[128079],
// 	[55356, 9993],
// 	[55356, 9999],
// 	[7999, 53],
// 	[9935],
// 	[174],
// 	[128078],
// 	[128077],
// 	[8011, 66],
// 	[10182],
// 	[10160],
// 	[9851],
// 	[55356, 9992],
// 	[9745],
// 	[9968],
// ].map((emojiCode) => String.fromCodePoint.apply(null, emojiCode));

function App() {
	// const [eoc, setEoc] = useState(0);

	// const emoji = (
	// 	<svg>
	// 		<g id="gBox">
	// 			{EMOJIS.map((emoji) => {
	// 				return <text className="svgrect-el">{emoji}</text>;
	// 			})}
	// 		</g>
	// 	</svg>
	// );

	// const onClick = () => {
	// 	var svgBox = document.getElementById("gBox");
	// 	var svgElems = Array.from(svgBox.getElementsByClassName("svgrect-el"));
	// 	var consistentEmoji = svgElems.find((el) => el.innerHTML === EMOJIS[0]);
	// 	var baseExtentOfChar = consistentEmoji.getExtentOfChar(EMOJIS[0]);
	// 	var extentOfCharSum = Object.keys(baseExtentOfChar.__proto__).reduce(
	// 		(acc, key) => {
	// 			var val = baseExtentOfChar[key];
	// 			return isNaN(val) ? acc : acc + val;
	// 		},
	// 		0,
	// 	);
	// 	setEoc(extentOfCharSum);
	// };

	return (
		<div className="App">
			<header className="App-header">
				{/* <p>rendered emoji</p> */}
				{/* {emoji} */}
				{/* <p>extent of char of emoji</p> */}
				{/* <button type="button" onClick={onClick}> */}
				{/* 	click me */}
				{/* </button> */}
				{/* <p>{eoc}</p> */}
				<p>Force Interactivity Test</p>
				<div
					className="cf-turnstile"
					data-sitekey="0x4AAAAAAAce28CtFDd-1c-1"
					data-callback="javascriptCallback"
				></div>
			</header>
		</div>
	);
}

export default App;
